<template>
  <div class="meter">
    <span :style="'width:'+this.value+'%'"></span>
  </div>
</template>
<script>
export default {
  name: "",
  props: ['value']  
}
</script>
<style>
.meter {
  box-sizing: content-box;
  height: 20px;
  position: relative;
  border-radius: 25px;
  padding: 5px;
}
.meter > span {
  display: block;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #fbb0cc;
  background-image: linear-gradient(center bottom, rgb(43, 194, 83) 37%, rgb(84, 240, 84) 69%);
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
}
</style>
