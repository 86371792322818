<template>
  <div class="">
    <b-row>
      <b-col md="8" offset-md="2">
        <div class="p-5">
          <ProgressBar :value="step100"/>
          <b-card>
            <form ref="form">
              <b-row v-if="step < 3">
                <p class="h4 text-dark mt-3">Etape 1:</p>
                <p class="h6 text-dark mb-3">Indiquez les coordonnées de votre client:</p>
                <b-col md="4">
                  <b-form-input v-model="user.nom" placeholder="Nom" required></b-form-input>
                </b-col>
                <b-col md="4">
                  <b-form-input v-model="user.prenom" placeholder="Prenom" required></b-form-input>
                </b-col>
                <b-col md="4">
                  <b-form-input type="email" v-model="user.email" placeholder="Email" required></b-form-input>
                </b-col>
                <b-col md="4" class="mt-4" v-if="step === 1">
                  <b-button block variant="primary" class="text-white" @click="check()" required>Suivant</b-button>
                </b-col>
              </b-row>
              <div v-if="step === 2">
                <p class="h4 text-dark mt-3">Etape 2:</p>
                <p class="h6 text-dark mb-3">Indiquez quel type de lien vous souhaitez envoyer à votre client:</p>
                <b-row>
                  <b-col md="4" class="p-4 text-center border rounded" @click="provisoir = true" :class="provisoir ? 'border-success bg-success' : 'border-primary bg-primary'">
                    <b-form-radio v-model="provisoir" name="provisoir" :value="true" class="text-white">Attestation provisoire</b-form-radio>
                  </b-col>
                  <b-col md="8">
                    Votre client est activement à la recherche d’un logement en location vide ou meublée. En lui envoyant ce lien, vous l’invitez à créer son compte Pautions et à récupérer son attestation provisoire pour ensuite l’ajouter à son dossier de candidature.
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <b-col md="4" class="p-4 text-center border rounded" @click="provisoir = false" :class="provisoir === false ? 'border-success bg-success' : 'border-primary bg-primary'">
                    <b-form-radio v-model="provisoir" name="provisoir" :value="false" class="text-white">Souscription directe</b-form-radio>
                  </b-col>
                  <b-col md="8">
                    Votre client a trouvé son futur logement et est en possession de son bail ou projet de bail. En lui envoyant ce lien, vous l’invitez à souscrire à l’assurance Zéro Dépôt et à ne pas verser de dépôt de garantie au propriétaire/bailleur lors de la signature du bail.
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <b-col md="4" v-if="provisoir != null">
                    <b-button block variant="primary" class="text-white" @click="send()" required>Envoyer</b-button>
                  </b-col>
                </b-row>
              </div>
              <div v-if="step === 3">
                <p class="h4 text-primary mt-3">Votre lien a bien été envoyé</p>
                <p class="text-dark">Un mail a été envoyé à {{user.nom}} {{user.prenom}} pour réaliser une {{provisoir ? 'Attestation provisoire' : 'Souscription directe'}}</p>
                <b-button variant="primary" @click="reset()">Proposer une nouvelle inscription</b-button>
                <b-button variant="primary" class="ms-4" href="/#/partenaire/liste">Retour</b-button>
              </div>
            </form>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import ProgressBar from '@/components/ProgressBar'
export default {
  name: "Manuelle",
  components: {
    ProgressBar
  },
  data(){
    return {
      step:1,
      user:{
        nom:null,
        prenom:null,
        email: null
      },
      provisoir:null
    }
  },
  methods: {
    check(){
      if(!this.$refs.form.reportValidity()){
        return false
      }
      this.step = 2
    },
    send(){
      var params = {
        user: this.user,
        provisoir: this.provisoir
      }
      this.$store.api.ajax('/partenaire/manuelle', params, res => {
        if(res.status){
          this.step = 3;
        }
      })
    },
    reset(){
      this.user = {
        nom:null,
        prenom:null,
        email:null
      };
      this.provisoir = null;
      this.step = 1;
    }
  },
  computed:{
    step100(){
      return parseInt(this.step * 33.3333)
    }
  },
  beforeMount(){},
  mounted(){}
}
</script>
<style scoped>
</style>
